import React from 'react'

const Single = () => {
    return (
        <div className='single'>
            <div className="content">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlqaL1CQ42XgaKv-Wlyj6yDOEfPznCV2DfbQ&usqp=CAU" alt="" />
            </div>

            <div className="user">
                <img src="" alt="" />
            </div>

            <div className="info">
                <span>John</span>
                <p>Posted 2 days ago</p>
            </div>

            <div>

            </div>
            <div className="menu">
                m
            </div>
        </div>
    )
}

export default Single
