// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#progress {
    appearance: none;
}
#progress::-webkit-progress-bar {
    background:#f0f0f0;
    border-radius:10px;
    box-shadow: inset 2px 2px 10px #ccc;
}
#progress::-webkit-progress-value {
    border-radius:10px;
    background: #0000b3;
    background: linear-gradient(to right,  #ccccff, #4d4dff);

}
`, "",{"version":3,"sources":["webpack://./src/pages/progress.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,mCAAmC;AACvC;AACA;IACI,kBAAkB;IAClB,mBAAmB;IAEnB,wDAAwD;;AAE5D","sourcesContent":["#progress {\r\n    appearance: none;\r\n}\r\n#progress::-webkit-progress-bar {\r\n    background:#f0f0f0;\r\n    border-radius:10px;\r\n    box-shadow: inset 2px 2px 10px #ccc;\r\n}\r\n#progress::-webkit-progress-value {\r\n    border-radius:10px;\r\n    background: #0000b3;\r\n    background: -webkit-linear-gradient(to right,  #ccccff, #4d4dff);\r\n    background: linear-gradient(to right,  #ccccff, #4d4dff);\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
