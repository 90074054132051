// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 
.button {
    background-color:#6666ff;
    color: white;
    border: none;
    border-radius: 5px;
    height: 45px;
    width: 80px;
}
 

 
.input {
    outline: white;
    border: 1px dotted #4d4dff;
    background: white;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.8);
    height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/InputWithButton.css"],"names":[],"mappings":";;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;;;AAIA;IACI,cAAc;IACd,0BAA0B;IAC1B,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["\r\n \r\n.button {\r\n    background-color:#6666ff;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 5px;\r\n    height: 45px;\r\n    width: 80px;\r\n}\r\n \r\n\r\n \r\n.input {\r\n    outline: white;\r\n    border: 1px dotted #4d4dff;\r\n    background: white;\r\n    border-radius: 5px;\r\n    color: rgba(0, 0, 0, 0.8);\r\n    height: 40px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
