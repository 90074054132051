import React from 'react'
import { Link } from 'react-router-dom'

const Profile = () => {
    return (
        <div>Profile</div>
    )
}

export default Profile
