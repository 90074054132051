import React from 'react'
import { Link } from 'react-router-dom'

const Payment = () => {
    return (
        <div>Payment</div>
   
    )
}

export default Payment
