// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.menu{
    position: absolute;
    top: 100%; /* 박스 아래로 위치시킴 */
    left: 30%;
    width: 120px;
    flex-direction: row;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    
    li{
    list-style: none;
    }

    li:hover {
      background-color: #f2f2f2;
    }
}









/* @keyframes slide-fade-in-dropdown-animation {
    0% {
      transform: translateY(-100%);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  .slide-fade-in-dropdown {
    overflow: hidden;
  }
  
  .slide-fade-in-dropdown > ul {
    animation: slide-fade-in-dropdown-animation .4s ease;
  }
  
  @keyframes slide-fade-out-dropdown-animation {
    0% {
      transform: translateY(0);
    }
  
    100% {
      transform: translateY(-100%);
    }
  }
  
  .slide-fade-out-dropdown {
    overflow: hidden;
  }
  
  .slide-fade-out-dropdown > ul {
    animation: slide-fade-out-dropdown-animation 0.4s ease;
    animation-fill-mode: forwards;
  }
  
  .components-dropdown > ul {
    position: relative;
    top: 5px;
    margin-top: 0;
    margin-bottom: 5px;
    padding-left: 0;
    list-style: none;
  } */`, "",{"version":3,"sources":["webpack://./src/components/Dropdown.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,SAAS,EAAE,gBAAgB;IAC3B,SAAS;IACT,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,sBAAsB;IACtB,aAAa;IACb,wCAAwC;;IAExC;IACA,gBAAgB;IAChB;;IAEA;MACE,yBAAyB;IAC3B;AACJ;;;;;;;;;;AAUA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;KA4CK","sourcesContent":["\r\n.menu{\r\n    position: absolute;\r\n    top: 100%; /* 박스 아래로 위치시킴 */\r\n    left: 30%;\r\n    width: 120px;\r\n    flex-direction: row;\r\n    background-color: #fff;\r\n    border: 1px solid #ccc;\r\n    padding: 10px;\r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\r\n    \r\n    li{\r\n    list-style: none;\r\n    }\r\n\r\n    li:hover {\r\n      background-color: #f2f2f2;\r\n    }\r\n}\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n/* @keyframes slide-fade-in-dropdown-animation {\r\n    0% {\r\n      transform: translateY(-100%);\r\n    }\r\n  \r\n    100% {\r\n      transform: translateY(0);\r\n    }\r\n  }\r\n  \r\n  .slide-fade-in-dropdown {\r\n    overflow: hidden;\r\n  }\r\n  \r\n  .slide-fade-in-dropdown > ul {\r\n    animation: slide-fade-in-dropdown-animation .4s ease;\r\n  }\r\n  \r\n  @keyframes slide-fade-out-dropdown-animation {\r\n    0% {\r\n      transform: translateY(0);\r\n    }\r\n  \r\n    100% {\r\n      transform: translateY(-100%);\r\n    }\r\n  }\r\n  \r\n  .slide-fade-out-dropdown {\r\n    overflow: hidden;\r\n  }\r\n  \r\n  .slide-fade-out-dropdown > ul {\r\n    animation: slide-fade-out-dropdown-animation 0.4s ease;\r\n    animation-fill-mode: forwards;\r\n  }\r\n  \r\n  .components-dropdown > ul {\r\n    position: relative;\r\n    top: 5px;\r\n    margin-top: 0;\r\n    margin-bottom: 5px;\r\n    padding-left: 0;\r\n    list-style: none;\r\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
